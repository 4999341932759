import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import Instructions from './Instructions';
import Device from './Device';
import textResource from './textResource.json';

function Header(props) {
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <header className='App-header'>
      <h1>{textResource.pageTitle}</h1>
      <nav>
        {props.active && (
          <ul>
            {props.authlevel === 'Admin' && (
              <li>
                <NavLink to='/register'>Register</NavLink>
              </li>
            )}
            <li onClick={togglePopup}>Instructions</li>
            {props.authlevel === 'Admin' && (
              <li>
                <NavLink to='/csv-page'>Download csv files</NavLink>
              </li>
            )}
            <button onClick={props.handleLogout}>Logout</button>
          </ul>
        )}
      </nav>
      {showPopup ? <Instructions togglePopup={togglePopup} /> : null}
      <Device />
    </header>
  );
}

export default Header;
