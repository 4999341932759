import React, { useState } from 'react';
import { postAssessment } from '../api/backend';
import { getUserIdFromSession, getUser } from '../actions/auth';
import { removeUserSession } from '../actions/auth';
import RadioInput from './Radiobutton';
import textResource from './textResource.json';
import './Form.css';

const CaseForm = (props) => {
  const { caseId } = props;
  const [selectedAssessment, setSelectedAssessment] = useState('');
  const [selectedConfidence, setSelectedConfidence] = useState('');
  const [selectedImageQuality, setSelectedImageQuality] = useState('');
  const [comment, setComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const resetState = () => {
    setErrorMessage('');
    setSelectedAssessment('');
    setSelectedConfidence('');
    setSelectedImageQuality('');
    setComment('');
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const assessmentObj = {
      caseId,
      confidence: selectedConfidence,
      assessment: selectedAssessment,
      imageQuality: selectedImageQuality,
      comment: comment,
      user: getUserIdFromSession(),
      category: getUser().category,
    };

    if (
      assessmentObj.caseId &&
      assessmentObj.user &&
      assessmentObj.assessment &&
      assessmentObj.confidence &&
      assessmentObj.imageQuality
    ) {
      try {
        const { ok, status } = await postAssessment(assessmentObj);
        if (ok) {
          resetState();
          props.loadNew();
        } else if (status === 401) {
          removeUserSession();
          alert(textResource.loggedOutAlert);
          window.location.reload();
        } else {
          setErrorMessage(textResource.serverError);
        }
      } catch (err) {
        setErrorMessage(textResource.serverError);
      }
    } else {
      setErrorMessage(textResource.reqestError);
    }
  };

  return (
    <form className='case-form' onSubmit={(e) => onSubmit(e)}>
      <div className='input-wrapper'>
        <div className='question'>
          <div className='question-header'>
            <p>Diagnosis</p>
          </div>
          <div>
            <RadioInput
              label='Benign'
              value='benign'
              checked={selectedAssessment}
              setter={setSelectedAssessment}
            />
          </div>
          <div>
            <RadioInput
              label='Malignant'
              value='malignant'
              checked={selectedAssessment}
              setter={setSelectedAssessment}
            />
          </div>
        </div>
        <div className='question'>
          <div className='question-header'>
            <p>Confidence</p>
          </div>
          <div>
            <RadioInput
              label='Certainly B/M'
              value='certainly'
              checked={selectedConfidence}
              setter={setSelectedConfidence}
            />
          </div>
          <div>
            <RadioInput
              label='Probably B/M'
              value='probably'
              checked={selectedConfidence}
              setter={setSelectedConfidence}
            />
          </div>
          <div>
            <RadioInput
              label='Uncertain'
              value='uncertain'
              checked={selectedConfidence}
              setter={setSelectedConfidence}
            />
          </div>
        </div>
        <div className='question'>
          <div className='question-header'>
            <p>Image quality</p>
          </div>
          <div>
            <RadioInput
              label='Adequate'
              value='adequate'
              checked={selectedImageQuality}
              setter={setSelectedImageQuality}
            />
          </div>
          <div>
            <RadioInput
              label='Inadequate'
              value='inadequate'
              checked={selectedImageQuality}
              setter={setSelectedImageQuality}
            />
          </div>
        </div>
        <div className='question'>
          <div className='question-header'>
            <p>Comment</p>
          </div>
          <textarea onChange={(event) => setComment(event.target.value)} />
        </div>
      </div>

      <div>
        <input
          className='button button-case-form'
          type='submit'
          value='Submit / Next Case'
          disabled={!selectedAssessment || !selectedConfidence || !selectedImageQuality}
        />
      </div>
      <div className='error-message'>{errorMessage}</div>
    </form>
  );
};

export default CaseForm;
