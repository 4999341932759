import React, { useState } from 'react';
import './Slider.css';
import Spinner from './Spinner';

const Slide = ({ content }) => {
  const [loading, setLoading] = useState(true);

  const handleEvent = (e) => {
    e.preventDefault();
    return false;
  };

  const stopSpinner = () => {
    setLoading(false);
  };

  return (
    <div className='slider-div' onContextMenu={handleEvent}>
      {loading && <Spinner />}
      <img
        className='slider-img'
        onContextMenu={handleEvent}
        onDragStart={handleEvent}
        src={content}
        alt=''
        onLoad={stopSpinner}
        onError={stopSpinner}
      />
      <div className='slider-cover'></div>
    </div>
  );
};

export default Slide;
