import React, { useState } from 'react';
import './Slider.css';
import Spinner from './Spinner';

const GalleryImage = ({ content, galleryClass, togglePopup, id }) => {
  const [loading, setLoading] = useState(true);

  const handleEvent = (e) => {
    e.preventDefault();
    return false;
  };

  const stopSpinner = () => {
    setLoading(false);
  };
  const handleClick = () => {
    togglePopup(id);
  };

  return (
    <div className={galleryClass} onClick={handleClick} onContextMenu={handleEvent}>
      {loading && <Spinner />}
      <div className='gallery-cover'></div>
      <img
        className='gallery-image'
        onClick={handleClick}
        onContextMenu={handleEvent}
        onDragStart={handleEvent}
        src={content}
        alt=''
        onLoad={stopSpinner}
        onError={stopSpinner}
      />
    </div>
  );
};

export default GalleryImage;
