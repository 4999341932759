import React, { useEffect, useState } from 'react';
import Slider from './Slider';

import CaseForm from './CaseForm';
import Spinner from './Spinner';

import {
  fetchCaseByUser,
  fetchImagesByCase,
  imageUriByKey,
  fetchAllCasesByUser,
  fetchAssessmentsByUser,
} from '../api/backend';
import { getUserIdFromSession, removeUserSession } from '../actions/auth';
import textResource from './textResource.json';

const Case = (props) => {
  const [loadNewCase, setLoadNewCase] = useState(true);

  // Array of URL:s.
  const [caseImages, setCaseImages] = useState([]);

  const [currentCaseId, setCurrentCaseId] = useState('');

  const [counter, setCounter] = useState('');

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const loadNew = () => {
    setLoadNewCase(!loadNewCase);
  };

  useEffect(() => {
    const fetchCases = async () => {
      const userId = getUserIdFromSession();
      setLoading(true);
      try {
        const res = await fetchCaseByUser(userId);
        const data = await res.json();
        if (res.status === 401) {
          setLoading(false);
          removeUserSession();
          alert(textResource.loggedOutAlert);
          window.location.reload();
        } else if (!data.currentCase && data.data) {
          setLoading(false);
          setErrorMessage(res.status + ': ' + data.data);
        } else if (!data.currentCase && data.message) {
          setCaseImages([]);
          setLoading(false);
          setErrorMessage(textResource.messageForNoCase);
        } else if (data.currentCase) {
          setCurrentCaseId(data.currentCase);
          await getImages(data.currentCase);
        }
      } catch (e) {
        setLoading(false);
        setErrorMessage(textResource.serverError);
      }
    };

    const getImages = async (newCase) => {
      try {
        const res = await fetchImagesByCase(newCase);
        const data = await res.json();
        if (data.images.length === 0) {
          setLoading(false);
          setErrorMessage(textResource.fetchingImagesError);
        } else {
          const imageUrls = [];
          data.images.forEach((img) => {
            imageUrls.push(imageUriByKey(img.Key));
          });
          setCaseImages(imageUrls);
          const id = getUserIdFromSession();
          fetchNumberOfCases(id);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        setErrorMessage(textResource.fetchingImagesError);
      }
    };

    const fetchNumberOfCases = async (userId) => {
      try {
        const totalNumber = await fetchAllCasesByUser(userId);
        const assessedNumber = await fetchAssessmentsByUser(userId);
        setCounter(`Submitted assessments: ${assessedNumber} out of ${totalNumber}.`);
      } catch (e) {
        setErrorMessage(textResource.caseCounterError);
      }
    };
    fetchCases();
  }, [loadNewCase]);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {caseImages.length > 0 ? (
            <div className='case-page'>
              <div>
                <p className='counter'>{counter}</p>
                <Slider slides={caseImages} />
              </div>
              <CaseForm caseId={currentCaseId} loadNew={loadNew} />
            </div>
          ) : (
            <div className='error-message'>{errorMessage}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Case;
