import React from 'react';
import textResource from './textResource.json';
import { server } from '../api/backend';
import { getUserSession } from '../actions/auth';

function CsvPage() {
  const token = getUserSession();

  return (
    <div>
      <h1>{textResource.downloadCsvs}</h1>
      <div>
        <p>
          <a href={`${server}/api/assessments_as_csv?token=${token}&type=assessments`}>
            Download assessments CSV
          </a>
        </p>
        <p>
          <a href={`${server}/api/assessments_as_csv?token=${token}&type=confidence`}>
            Download confidence CSV
          </a>
        </p>
        <p>
          <a href={`${server}/api/assessments_as_csv?token=${token}&type=quality`}>
            Download image quality CSV
          </a>
        </p>
        <p>
          <a href={`${server}/api/assessments_as_csv?token=${token}&type=comment`}>
            Download comments CSV
          </a>
        </p>
      </div>
    </div>
  );
}

export default CsvPage;
