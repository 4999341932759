import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './Instructions.css';
import { fetchInstructions } from '../api/backend';
import Spinner from './Spinner';
import textResource from './textResource.json';

function Instructions(props) {
  useEffect(() => {
    (async function getInstructions() {
      setLoading(true);
      try {
        const res = await fetchInstructions();
        const text = await res.json();
        if (!text.text) {
          setLoading(false);
          setErrorMessage(textResource.instructionsErrorMessage);
        } else {
          setLoading(false);
          setInstructionsText(text.text);
        }
      } catch {
        setLoading(false);
        setErrorMessage(textResource.serverError);
      }
    })();
  }, []);

  const [instructionsText, setInstructionsText] = useState('');

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  return (
    <div className='popup'>
      <div className='popup-inner'>
        <div onClick={props.togglePopup} className='closing-x-div'>
          <i className='fas fa-times'></i>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <div className='error-message'>{errorMessage}</div>
            <p>
              <ReactMarkdown>{instructionsText}</ReactMarkdown>
            </p>
            <div className='buttonDiv'>
              <button onClick={props.togglePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Instructions;
