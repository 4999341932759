import React from 'react';
import './Slider.css';

const Arrow = ({ direction, handleClick }) => {
  let angleClass = '';
  let arrowStyle = '';
  if (direction === 'right') {
    arrowStyle = 'arrowStyle right';
    angleClass = 'iconStyle far fa-arrow-alt-circle-right';
  } else {
    arrowStyle = 'arrowStyle left';
    angleClass = 'iconStyle far fa-arrow-alt-circle-left';
  }
  return (
    <div className={arrowStyle} onClick={handleClick}>
      <i className={angleClass}></i>
    </div>
  );
};

export default Arrow;
