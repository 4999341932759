import React, { useState } from 'react';
import { sendEmail } from '../api/backend';
import textResource from './textResource.json';

const ForgottenPasswordForm = () => {
  const [formData, setFormData] = useState({ email: '' });
  const email = formData.email;

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    const body = {
      email: textResource.adminEmailAddress,
      subject: textResource.emailSubject,
      user_email: email,
      email_body: `${textResource.passwordResetEmailText} ${email}`,
    };
    const res = await sendEmail(body);
    if (res.ok) {
      setSuccessMessage(textResource.forgottenPasswordSuccessMessage);
      setErrorMessage('');
    } else {
      setErrorMessage(textResource.notAbleToSendEmail);
      setSuccessMessage('');
    }
  };

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <div className='forgotten-password'>
      <form className='Form' onSubmit={(e) => onSubmit(e)}>
        <p>{textResource.forgottenPasswordText}</p>
        <div>
          <input
            type='email'
            placeholder={textResource.forgottenPasswordPlaceholder}
            name='email'
            value={email}
            onChange={onChange}
            required
          />
        </div>
        <div>
          <input
            type='submit'
            className='button'
            value={textResource.forgottenPasswordButtonText}
          />
        </div>
        <div className='success-message'>{successMessage}</div>
        <div className='error-message'>{errorMessage}</div>
      </form>
    </div>
  );
};
export default ForgottenPasswordForm;
