import React from 'react';
import textResource from './textResource.json';

function Device(props) {
  return (
    <div className='device'>
      <div className='popup'>
        <div className='popup-inner' id='device-message'>
          <div>
            <h3>{textResource.promptToUseALargerDevice}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Device;
