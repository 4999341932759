import { login } from '../api/backend';

export const loginUser = async (email, password) => {
  const body = { email, password };
  const res = await login(body);
  return res;
};

export const setUserSession = (user) => {
  const encodedData = window.btoa(JSON.stringify(user));
  sessionStorage.setItem('userSecond', encodedData);
};

export const getUserSession = () => {
  const userSecond = sessionStorage.getItem('userSecond');
  if (userSecond) {
    const decodedData = window.atob(userSecond);
    const obj = JSON.parse(decodedData);
    if (obj) {
      return obj.token;
    }
  }
};

export const getUserAuthlevel = () => {
  const userSecond = sessionStorage.getItem('userSecond');
  if (userSecond) {
    const decodedData = window.atob(userSecond);
    const obj = JSON.parse(decodedData);
    if (obj) {
      return obj.authlevel;
    }
  }
};

export const getUserIdFromSession = () => {
  const userSecond = sessionStorage.getItem('userSecond');
  if (userSecond) {
    const decodedData = window.atob(userSecond);
    const obj = JSON.parse(decodedData);
    if (obj) {
      return obj._id;
    }
  }
};

export const getUser = () => {
  const userSecond = sessionStorage.getItem('userSecond');
  if (userSecond) {
    const decodedData = window.atob(userSecond);
    const obj = JSON.parse(decodedData);
    if (obj) {
      return obj;
    }
  }
};

export const removeUserSession = () => {
  sessionStorage.clear();
};
