import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Page from './components/Page';
import Register from './components/Register';
import Login from './components/Login';
import Case from './components/Case';
import CsvPage from './components/CsvPage';
import { removeUserSession, getUserSession, getUserAuthlevel, getUser } from './actions/auth';

function App(props) {
  const [appState, setAppState] = useState({
    active: false,
    authlevel: '',
  });

  useEffect(() => {
    const res = getUser();
    if (res) {
      setAppState({
        active: res.active,
        authlevel: res.authlevel,
      });
    }
  }, []);

  const updateUserState = async (res) => {
    setAppState({
      ...appState,
      active: res.active,
      authlevel: res.authlevel,
    });
  };

  const handleLogout = () => {
    removeUserSession();
    setAppState({
      ...appState,
      active: false,
      authlevel: '',
    });
  };

  return (
    <Page handleLogout={handleLogout} authlevel={appState.authlevel} active={appState.active}>
      <Route
        exact
        path='/'
        render={(props) => <Login {...props} updateUserState={updateUserState} />}
      />
      <Route
        exact
        path='/register'
        render={(props) =>
          getUserSession() && getUserAuthlevel() === 'Admin' ? (
            <Register {...props} handleLogout={handleLogout} />
          ) : (
            <Redirect to={{ pathname: '/case', state: { from: props.location } }} />
          )
        }
      />
      <Route
        exact
        path='/csv-page'
        render={(props) =>
          getUserSession() && getUserAuthlevel() === 'Admin' ? (
            <CsvPage {...props} handleLogout={handleLogout} />
          ) : (
            <Redirect to={{ pathname: '/case', state: { from: props.location } }} />
          )
        }
      />
      <Route
        exact
        path='/case'
        render={(props) =>
          getUserSession() ? (
            <Case {...props} handleLogout={handleLogout} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />
    </Page>
  );
}

export default App;
