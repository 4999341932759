import { getUserSession } from '../actions/auth';

export const server = 'https://omlc.herokuapp.com';
const imageServer = 'https://omlc-images.herokuapp.com';

export const fetchCaseByUser = async (userId) => {
  const token = getUserSession();
  const results = await fetch(`${server}/api/new_case/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      token: token,
    },
  });
  return results;
};

export const fetchInstructions = async () => {
  const token = getUserSession();
  const results = await fetch(`${server}/api/instructions`, {
    headers: {
      'Content-Type': 'application/json',
      token: token,
    },
  });
  return results;
};

// Number of cases for one user.
export const fetchAllCasesByUser = async (userId) => {
  const token = getUserSession();
  try {
    const results = await fetch(`${server}/api/cases/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await results.json();
    if (data) {
      const number = data[0].cases.length;
      return number;
    }
  } catch (e) {
    console.log('Cases could not be loaded.');
  }
};

export const fetchImagesByCase = async (caseId) => {
  const token = getUserSession();
  const results = await fetch(`${server}/api/images/${caseId}`, {
    headers: {
      'Content-Type': 'application/json',
      token: token,
    },
  });
  return results;
};

export const imageUriByKey = (key) => {
  return `${imageServer}/api/image/${encodeURIComponent(key)}`;
};

export const createUser = async (user) => {
  const token = getUserSession();
  const response = await fetch(`${server}/api/user/create`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      token: token,
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(user),
  });
  return response;
};

export const login = async (user) => {
  const response = await fetch(`${server}/api/login`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'same-origin',
    headers: new Headers({
      Authorization: 'Basic ' + btoa(`${user.email}:${user.password}`),
      'Content-Type': 'application/json',
    }),
    referrerPolicy: 'no-referrer',
  });
  return response;
};

// Number of assessments done
export const fetchAssessmentsByUser = async (user) => {
  const token = getUserSession();
  const results = await fetch(`${server}/api/assessments/${user}`, {
    headers: {
      'Content-Type': 'application/json',
      token: token,
    },
  });
  const data = await results.json();
  if (data) {
    const number = data.length;
    return number;
  }
};

export const postAssessment = async (assessment) => {
  const token = getUserSession();
  const response = await fetch(`${server}/api/assessment`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      token: token,
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(assessment),
  });
  return response;
};

export const sendEmail = async (emailBody) => {
  const response = await fetch(`${server}/api/email`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(emailBody),
  });
  return response;
};

export const getUserById = async (id) => {
  const token = getUserSession();
  const response = await fetch(`${server}/api/user/${id}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      token: token,
    },
    referrerPolicy: 'no-referrer',
  });
  return response;
};
