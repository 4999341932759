import React, { useState } from 'react';
import SliderContent from './SliderContent';
import Slide from './Slide';
import Arrow from './Arrow';
import './Slider.css';
import Dots from './Dots';
import GalleryImage from './GalleryImage';
import classNames from 'classnames';

const Slider = (props) => {
  const getWidth = () => window.innerWidth;

  const [state, setState] = useState({
    translate: 0,
    transition: 0.45,
    activeIndex: 0,
  });

  const { translate, transition, activeIndex } = state;

  const nextSlide = () => {
    if (activeIndex === props.slides.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0,
      });
    }

    setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: (activeIndex + 1) * getWidth(),
    });
  };

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate: (props.slides.length - 1) * getWidth(),
        activeIndex: props.slides.length - 1,
      });
    }

    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: (activeIndex - 1) * getWidth(),
    });
  };

  const galleryClass = classNames({
    'gallery-image-one': props.slides.length === 1,
    'gallery-image-two': props.slides.length === 2,
    'gallery-image-three': props.slides.length === 3,
    'gallery-image-four-plus': props.slides.length >= 4,
  });

  //Toggle slider.
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = (id) => {
    setShowPopup(!showPopup);
    setState({
      ...state,
      activeIndex: id,
      translate: id * getWidth(),
    });
  };

  return (
    <div>
      {showPopup ? (
        <div className='popup-slider'>
          <div onClick={togglePopup} className='closing-x-slider'>
            <i className='closing far fa-times-circle'></i>
          </div>
          <div className='slider'>
            <SliderContent
              translate={translate}
              transition={transition}
              width={getWidth() * props.slides.length}
            >
              {props.slides.map((slide, i) => (
                <Slide key={slide + i} content={slide} />
              ))}
            </SliderContent>
            <Arrow direction='left' handleClick={prevSlide} />
            <Arrow direction='right' handleClick={nextSlide} />
            <Dots slides={props.slides} activeIndex={activeIndex} />
          </div>
        </div>
      ) : null}
      <div className='gallery'>
        {props.slides.map((slide, i) => (
          <GalleryImage
            key={slide + i}
            id={i}
            content={slide}
            galleryClass={galleryClass}
            togglePopup={togglePopup}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
