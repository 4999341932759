import React, { useState } from 'react';
import { createUser, getUserById } from '../api/backend';
import './Form.css';
import Spinner from './Spinner';
import textResource from './textResource.json';
import { removeUserSession, getUserIdFromSession } from '../actions/auth';
import RadioInput from './Radiobutton';

const Register = (props) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    password2: '',
    authlevel: 'User',
    category: '',
  });

  const { email, password, password2, authlevel, category } = formData;

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const radioOnChange = (value) => {
    setFormData({ ...formData, category: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (password !== password2) {
      setLoading(false);
      setErrorMessage(textResource.passwordMissmatchError);
      setSuccessMessage('');
    } else {
      const userId = getUserIdFromSession();
      const res = await getUserById(userId);
      const data = await res.json();
      if (data.authlevel === 'Admin') {
        try {
          const res = await createUser({
            email,
            password,
            authlevel,
            category,
          });
          if (res.ok) {
            setLoading(false);
            setSuccessMessage(textResource.successfulRegistration);
            setErrorMessage('');
            setFormData({
              email: '',
              password: '',
              password2: '',
            });
          } else if (res.status === 409) {
            setLoading(false);
            setErrorMessage(textResource.conflictRegistration);
            setSuccessMessage('');
          } else if (res.status === 401) {
            setLoading(false);
            setSuccessMessage('');
            removeUserSession();
            alert(textResource.loggedOutAlert);
            window.location.reload();
          } else if (res.status === 403) {
            setLoading(false);
            setSuccessMessage('');
            removeUserSession();
            window.location.reload();
          }
        } catch (err) {
          setLoading(false);
          setErrorMessage(textResource.serverError);
          setSuccessMessage('');
        }
      } else {
        setLoading(false);
        setErrorMessage(textResource.authorizationError);
      }
    }
  };

  return (
    <div className='Form-wrapper'>
      <h1 className='Form-h1'>Create New Account</h1>
      {loading ? (
        <Spinner />
      ) : (
        <form className='Form' onSubmit={(e) => onSubmit(e)}>
          <div className='error-message'>{errorMessage}</div>
          <div className='success-message'>{successMessage}</div>
          <div>
            <input
              type='email'
              placeholder='Enter Email'
              name='email'
              value={email}
              onChange={onChange}
              required
            />
          </div>
          <div>
            <input
              type='password'
              placeholder='Enter Password'
              name='password'
              minLength='6'
              value={password}
              onChange={onChange}
            />
          </div>
          <div>
            <input
              type='password'
              placeholder='Repeat Password'
              name='password2'
              minLength='6'
              value={password2}
              onChange={onChange}
            />
          </div>
          <div className='radio-wrapper'>
            <RadioInput label='Expert' value='Expert' checked={category} setter={radioOnChange} />
            <RadioInput
              label='Non-expert'
              value='Nonexpert'
              checked={category}
              setter={radioOnChange}
            />
          </div>
          <input type='submit' className='button' value='Register' />
        </form>
      )}
    </div>
  );
};

export default Register;
