import React from 'react';
import './Slider.css';

const Dot = ({ active }) => {
  let dotStyle = '';
  if (active) {
    dotStyle = 'dotStyle black';
  } else {
    dotStyle = 'dotStyle gray';
  }

  return <span className={dotStyle} />;
};

const Dots = ({ slides, activeIndex }) => (
  <div className='dotsStyle'>
    {slides.map((slide, i) => (
      <Dot key={slide} active={activeIndex === i} />
    ))}
  </div>
);

export default Dots;
