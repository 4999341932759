import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '../App.css';
import Header from './Header';

function Page(props) {
  return (
    <Router>
      <div className='container'>
        <div>
          <Header
            authlevel={props.authlevel}
            active={props.active}
            handleLogout={props.handleLogout}
          />
          <main className='App'>{props.children}</main>
        </div>
      </div>
    </Router>
  );
}

export default Page;
