import React, { useState } from 'react';
import { loginUser } from '../actions/auth';
import { setUserSession } from '../actions/auth';
import Spinner from './Spinner';
import ForgottenPasswordForm from './ForgottenPasswordForm';
import textResource from './textResource.json';

const Login = (props) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;

  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await loginUser(email, password);
      if (res.ok) {
        setLoading(false);
        const session = await res.json();
        setUserSession(session);
        props.updateUserState(session);
        if (session.authlevel === 'Admin') {
          props.history.push('/register');
        } else {
          props.history.push('/case');
        }
      } else if (res.status === 401) {
        setLoading(false);
        setErrorMessage(textResource.loginError);
      }
    } catch (err) {
      setLoading(false);
      setErrorMessage(textResource.serverError);
    }
  };

  const [showInputField, setShowInputField] = useState(false);

  const handleForgottenPassword = (e) => {
    e.preventDefault();
    setShowInputField(!showInputField);
  };

  return (
    <div className='Form-wrapper'>
      <h1 className='Form-h1'>Login</h1>
      {loading ? (
        <Spinner />
      ) : (
        <form className='Form' onSubmit={(e) => onSubmit(e)}>
          <div className='error-message'>{errorMessage}</div>
          <div>
            <input
              type='email'
              placeholder='Email'
              name='email'
              value={email}
              onChange={onChange}
              required
            />
          </div>
          <div>
            <input
              type='password'
              placeholder='Password'
              name='password'
              minLength='6'
              value={password}
              onChange={onChange}
            />
          </div>
          <div>
            <input type='submit' className='button' value='Login' />
          </div>
          <div>
            <input
              type='button'
              onClick={handleForgottenPassword}
              className='button button-secondary'
              value='Forgotten Password?'
            />
          </div>
        </form>
      )}
      {showInputField ? <ForgottenPasswordForm /> : null}
    </div>
  );
};

export default Login;
